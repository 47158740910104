import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ - Foire aux questions - Net Masters Tennis Hub
			</title>
			<meta name={"description"} content={"Maîtriser le jeu, une question à la fois - Les réponses à vos questions sur le tennis"} />
			<meta property={"og:title"} content={"FAQ - Foire aux questions - Net Masters Tennis Hub"} />
			<meta property={"og:description"} content={"Maîtriser le jeu, une question à la fois - Les réponses à vos questions sur le tennis"} />
			<meta property={"og:image"} content={"https://alpineadventuresfr.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://alpineadventuresfr.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://alpineadventuresfr.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Les réponses au service du tennis
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Alors que vous vous apprêtez à fouler les courts du Net Masters Tennis Hub, nous avons compilé une liste de questions fréquemment posées pour vous aider à atteindre l'excellence dans le domaine du tennis.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Quels sont les programmes disponibles pour les débutants ?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Nous proposons une gamme de programmes pour débutants axés sur les fondamentaux, notamment des stages de groupe et des leçons particulières, afin de vous permettre de partir du bon pied.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Avez-vous des programmes pour les joueurs avancés ?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Oui, les joueurs avancés peuvent bénéficier de stages spécialisés, de séances d'entraînement à haute intensité et d'occasions de jouer en compétition afin d'affiner leurs compétences et leur stratégie.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Puis-je réserver un court pour mon usage personnel ?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Tout à fait ! Les membres et les invités peuvent réserver nos courts intérieurs et extérieurs pour des jeux personnels, des entraînements ou des matchs. Contactez-nous pour connaître les disponibilités et les modalités de réservation.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Quel équipement dois-je apporter ?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Nous vous recommandons d'apporter votre propre raquette pour des raisons de confort et de préférence personnelle, mais nous disposons d'une sélection de raquettes à louer. Une tenue de tennis correcte et des chaussures de tennis non marquantes sont nécessaires.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Y a-t-il des restrictions d'âge pour participer aux programmes ?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Nos programmes s'adressent à tous les âges, qu'il s'agisse de séances de développement pour les enfants ou de stages et de ligues pour les adultes. Chaque programme est adapté à l'âge et au niveau de compétence des participants.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Proposez-vous des entraînements privés ?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Oui, l'entraînement privé est disponible pour les joueurs qui recherchent une attention individuelle pour améliorer des aspects spécifiques de leur jeu. Les séances peuvent être réservées avec nos entraîneurs certifiés en fonction de votre emploi du temps.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Prêt pour le rallye
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Si votre question ne figure pas dans cette liste, n'hésitez pas à nous contacter. Notre équipe est prête à vous fournir toutes les informations dont vous avez besoin pour faire de votre expérience chez Net Masters un moment inoubliable.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://alpineadventuresfr.com/images/8.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});